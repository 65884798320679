import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout';
import Helmet from "react-helmet"

export const query = graphql`
    query industryReports {
        wpcontent {
            industryReports(first: 99) {
                nodes {
                    id
                    title
                    uri
                    excerpt
                    reportCoverImage
                }
            }
            seo{
                contentTypes {
                    industryReport {
                        metaDesc
                        title
                    }
                }
            }
        }
    }
`

const industryReports = ({ data }) => {
    const industryreports = data.wpcontent.industryReports.nodes
    const industryReportSeo = data.wpcontent.seo.contentTypes.industryReport
    const pageTitle = industryReportSeo.title
    const metaDesc = industryReportSeo.metaDesc

    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/hr-academy/industry-reports/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/hr-academy/industry-reports/"></link>
            </Helmet>
            <section className="bg-indigo-100  mx-auto py-10 pb-2 sm:py-20 sm:pb-0 md:py-40 relative">
                <div className="container max-w-7xl sm:text-center px-6">
                    <h1 className="hero-title-1-white">
                        <span className="block"><span className="text-orange">Industry</span> Reports</span>
                    </h1>
                    <p className="section-subtitle-white">
                        Our online library of industry reports offers actionable insights,<br/> comprehensive data, and in-depth analysis on thousands of global industries.
                    </p>
                </div>
                <img loading="lazy" className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L1.png" alt="Guides and Ebooks" />
                <img loading="lazy" className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L2.png" alt="Guides and Ebooks" />
                <img loading="lazy" className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R1.png" alt="Guides and Ebooks" />
                <img loading="lazy" className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R2.png.png" alt="Guides and Ebooks" />
            </section>
            <div className="bg-indigo-100 py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div>
            <section className="guides-list resource-listing container px-6 lg:px-0 max-w-7xl mx-auto pt-5 sm:pt-10 md:pt-40 md:-mt-72">
                <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {industryreports.map(industryreport => (
                        <article key={industryreport.id}>
                            <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                                <img loading="lazy" className="pb-0 rounded-sm" src={industryreport.reportCoverImage} alt={industryreport.title}/>
                                <div className="bg-white py-6 pb-2 h-full">
                                    <div className="text-orange uppercase text-sm">Industry Report</div>
                                    <h2 className="box-title font-bold mb-3 clamp-2 md:h-14" dangerouslySetInnerHTML={{ __html: industryreport.title }} />
                                    <div className="box-subtitle line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: industryreport.excerpt }} />
                                    <div className="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                        <div className="">
                                            <Link to={`${industryreport.uri}`} className="vc-colored-btn">Get the Report</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </section>  
            <section className="pb-10 md:py-40 md:pb-0">
                <div className="container max-w-7xl px-6">
                    <div className="text-center">
                        <h2 className="section-title">
                            Key Features of Our <span className="text-orange">Industry Reports</span>
                        </h2>
                        <p className="section-subtitle">
                            The report identifies a number of key themes and trends that are shaping the HR industry and presents each insight in a clear and concise manner. 
                        </p>
                    </div>
                    <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                        <div className="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2">
                            <div className="py-5 px-5 flex">
                                <div className="mt-5 mr-5">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//gatsbycms/uploads/2021/08/trend.png" alt="Based on the latest industry trends and best relevant practices"/>
                                </div>
                                <div className="w-2/3">
                                    <p className="box-subtitle-1 mt-3"><strong>Based on the latest industry trends and best relevant practices<br/></strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2">
                            <div className="py-5 px-5 flex">
                                <div className="mt-5 mr-5">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/08/research.png" alt="Extensive research that helps identify and mitigate industry risk"/>
                                </div>
                                <div className="w-2/3">
                                    <p className="box-subtitle-1 mt-3"><strong>Extensive research that helps identify and mitigate industry risk</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2">
                            <div className="py-5 px-5 flex">
                                <div className="mt-5 mr-5">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40,h_40/gatsbycms/uploads/2021/08/in-depth-research.png" alt="Comprehensive data and detailed analysis from global industry experts"/>
                                </div>
                                <div className="w-2/3">
                                    <p className="box-subtitle-1 mt-3"><strong>Comprehensive data and detailed analysis from global industry experts</strong></p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2">
                            <div className="py-5 px-5 flex">
                                <div className="mt-5 mr-5">
                                    <svg className="svgicon mx-auto" viewBox="0 0 40 40">
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M38.4,21.6H24.9c-0.6,0-1.1,0.5-1.1,1.1c0,0.3,0.1,0.6,0.3,0.8l9.5,9.5c0.2,0.2,0.5,0.3,0.8,0.3
                                                    c0.4,0,0.7-0.2,0.9-0.5c2.3-2.7,3.8-6.2,4.1-10c0-0.1,0-0.1,0-0.2C39.5,22,39,21.6,38.4,21.6z"/>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M20.8,2.1c-0.6,0-1.1,0.5-1.1,1.1v15c0,0.6,0.5,1.1,1.1,1.1h15c0.6,0,1.1-0.5,1.1-1.1
                                                    C36.4,9.6,29.5,2.7,20.8,2.1z"/>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M29.1,32L18,21c-0.2-0.2-0.3-0.5-0.3-0.7l0-15.7c0-0.6-0.5-1.1-1.1-1.1c-9,0.5-16.1,8-16.1,17.2
                                                    c0,9.5,7.7,17.2,17.2,17.2c4.3,0,8.3-1.6,11.3-4.2c0.2-0.2,0.4-0.5,0.4-0.8C29.4,32.5,29.3,32.2,29.1,32z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="w-2/3">
                                    <p className="box-subtitle-1 mt-3"><strong>In-depth research analysis to enhance employee engagement</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-6xl bg-purple-300 rounded-lg shadow-xl relative z-9 overflow-hidden my-20">
                    <div className="md:flex">
                        <div className="p-10 md:pl-32 md:pr-0 bottom-cta-content text-white md:w-2/3 w-full">
                            <div className="section-title-white text-center md:text-left">The <span className="text-orange">AIR<sup>e</sup></span> Framework</div>
                            <p className="section-subtitle-white">A must have for <strong>Future-Proof</strong> hybrid workplace</p>
                            <div className="mt-5 sm:mt-8 md:mt-8 flex justify-center md:justify-start">
                                <div className="">
                                    <a href="/hr-academy/aire-framework-employee-recognition/" className="vc-ghost-btn-cta-small-banner">
                                        Know more
                                    </a>
                                </div>
                            </div> 
                        </div>
                        <div className="w-1/3 relative mob">
                            <img loading="lazy" className="absolute bottom-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/08/AIRe-footer-illus.png" alt="AIRe Framework"/>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default industryReports

